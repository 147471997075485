export const environment = {
    name: 'hlg',
    version: 'HLG',
    production: false,
    graphQlUrl: 'https://james-api-hlg.backoffice.gpa.digital/graphql',
    graphQlBffUrl: 'https://api-hlg.backoffice.gpa.digital/orkestra/bff/graphql',
    bffApiUrl: 'https://api-hlg.backoffice.gpa.digital/orkestra/bff/api',
    apiUrl: 'https://james-api-hlg.backoffice.gpa.digital/api',
    bffUrl: 'https://api-hlg.backoffice.gpa.digital/orkestra/bff',
    localStorage: 'user',
    ssoEnabled: true,
    ssoUrl: 'https://api-hlg.backoffice.gpa.digital/orkestra/bff',
    mapUrl: 'https://james-dashboard-map-hlg.backoffice.gpa.digital',
};
