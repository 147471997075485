import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { ApolloLink, concat, InMemoryCache } from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';

import { environment } from 'environments/environment';
import { EnvironmentInterface } from '@shared/models/environment/environment.model';
import { SnackBarService } from '@core/services/snackbar/snackbar.service';
import { GraphQlType } from './graphql-type.enum';

@Injectable({
  providedIn: 'root'
})
export class GraphQlService {
  private apollo: Apollo;
  private httpLink: HttpLink;
  private snackbarService: SnackBarService;
  private env = (environment as EnvironmentInterface);

  constructor(
    apollo: Apollo,
    httpLink: HttpLink,
    snackbarService: SnackBarService
  ) {
    this.apollo = apollo;
    this.httpLink = httpLink;
    this.snackbarService = snackbarService;
  }

  /**
   * Create apollo client
   *
   * @memberof GraphQlService
   */
  public initApollo(): void {
    this.createApolloClient(GraphQlType.DEFAULT, this.env.graphQlUrl);
    this.createApolloClient(GraphQlType.BFF, this.env.graphQlBffUrl);
  }

  private createApolloClient(name: string, uri: string): void {
    const http = this.httpLink.create({ uri });
    const errorLink = this.createErrorLink();
    const authMiddleware = this.createAuthHeader();

    this.apollo.createNamed(name, {
      link: concat(errorLink, concat(authMiddleware, http)),
      cache: new InMemoryCache({ addTypename: false }),
      defaultOptions: {
        watchQuery: {
          errorPolicy: 'all',
          fetchPolicy: 'no-cache'
        },
        query: {
          fetchPolicy: 'no-cache',
        }
      }
    });
  }

  /**
   * Create a handler for request errors
   *
   * @private
   * @memberof GraphQlService
   */
  private createErrorLink(): ApolloLink {
    return onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.map(({ message, path }) => {
          console.log(`[GraphQL error]: Message: ${message}, Path: ${path}`);
          this.snackbarService.handleSnackBar(message);
        });
      }
      if (networkError) {
        console.log('[Network error]: ', networkError);
      }
    });
  }

  /**
   * Insert user token to header
   *
   * @private
   * @returns {ApolloLink}
   * @memberof GraphQlService
   */
  private createAuthHeader(): ApolloLink {
    return new ApolloLink((operation, forward) => {
      operation.setContext({
        // headers: new HttpHeaders().set('Authorization', this.user.token)
      });

      return forward(operation);
    });
  }
}
